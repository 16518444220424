<script setup lang="ts">
import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useConsumption } from '@/app-modules/explore/composables/useConsumption';
import type { OffProductionConsumption } from '~/types/analytic';

const props = defineProps({
  consumption: {
    type: Object as PropType<OffProductionConsumption>,
    required: true,
  },
});

const { t } = useI18n();
const { getConsumption } = useConsumption();
const route = useRoute();

const state = reactive({
  isLoading: false,
});

const data = reactive({
  consumption: [] as number[],
  power: [] as number[],
  timestamps: [] as string[],
});

const options: ChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: t('analytics.power_in_kw'),
        padding: 8,
      },
      grace: '5',
    },
    x: {
      title: {
        display: true,
        text: `${t('global.time')}`,
        padding: 8,
      },
      ticks: {
        autoSkip: true,
        callback: function (val: any) {
          return dayjs(this.getLabelForValue(val)).format('ddd HH:mm');
        },
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      pointStyle: false,
    },
    line: {
      borderWidth: 8,
    },
  },
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      position: 'average',
      rtl: true,
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          return `${label}: ${Math.round(value)} kW`;
        },
        title: (context: any) => {
          const date = new Date(context[0].label);
          return date.toLocaleTimeString('fr-FR', {
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
          });
        },
      },
    },
  },
};

async function loadData() {
  state.isLoading = true;

  if (!props.consumption?.start || !props.consumption?.end) {
    state.isLoading = false;
    return;
  }

  const consumptionData = await getConsumption({
    meterId: parseInt(route.params.id as string),
    start: dayjs(props.consumption.start).format('YYYY-MM-DDTHH:mm:ss'),
    end: dayjs(props.consumption.end).format('YYYY-MM-DDTHH:mm:ss'),
    aggregationLevel: 'minute',
  });

  if (consumptionData.length > 0) {
    data.timestamps = consumptionData.map((item: any) => item.timestamp);
    data.consumption = consumptionData.map((item: any) => item.consumption_kwh);
    data.power = consumptionData.map((item: any) => item.power_kw);
  }
  state.isLoading = false;
}

watch(
  () => props.consumption,
  () => loadData(),
  { immediate: true },
);
</script>

<template>
  <template v-if="!state.isLoading">
    <ui-chart
      type="line"
      :data="{
        labels: data.timestamps,
        datasets: [
          {
            label: $t('global.power'),
            data: data.power,
            borderWidth: 1,
            borderColor: '#FA541C',
            backgroundColor: '#FFF2E8',
          },
        ],
      }"
      :options="options"
      :plugins="{ 'chartjs-plugin-zoom': true, legend: false }"
    />
  </template>
  <ui-loader v-else />
</template>

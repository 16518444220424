<script setup lang="ts">
import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import type { Alert } from '~/types/alerts';
import type { OffProductionConsumption } from '~/types/analytic';

import OffConsumptionZoomGraph from './OffConsumptionZoomGraph.vue';

// Composables
const { formatNumberToIsoEuroNumber } = useNumbers();
const { t } = useI18n();

// Props
const props = defineProps<{
  baselinePowerKW: number;
  offProductionConsumptions: OffProductionConsumption[];
  alert: Alert | null;
}>();

// Data
const modal = ref(false);
const resetZoom = ref(false);
const selectedConsumption = ref<OffProductionConsumption>();

// Methods
const formatDate = (date: Date | string) => {
  const formatedDate = new Date(date);
  return formatedDate.toLocaleDateString(undefined, {
    weekday: 'short',
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Graph options
const options = computed((): ChartOptions => {
  return {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 2,
        },
        title: {
          display: true,
          text: t('analytics.avg_power_in_kW'),
          padding: 20,
        },
        grace: '25%',
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: ${Math.round(value)} kW`;
          },
          footer: (context: any) => {
            return [
              `${t('analytics.analysis_consumption')}: ${Math.round(
                props.offProductionConsumptions[context[0].dataIndex].consumption_kWh,
              )} kWh`,
              `${t('global.from_date')}: ${formatDate(props.offProductionConsumptions[context[0].dataIndex].start)}`,
              `${t('global.until')}: ${formatDate(props.offProductionConsumptions[context[0].dataIndex].end)}`,
              `${t('global.cost')}: ${formatNumberToIsoEuroNumber(props.offProductionConsumptions[context[0].dataIndex].cost)}`,
            ].join('\n');
          },
          title: (context: any) => {
            const formatedDate = new Date(props.offProductionConsumptions[context[0].dataIndex].start);
            return formatedDate.toLocaleDateString(undefined, {
              weekday: 'short',
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            });
          },
        },
      },
      annotation: {
        annotations: {
          energyLimitPriceAnnotation: {
            type: 'line',
            borderColor: 'rgba(50, 100, 0, 0.6)',
            borderWidth: 2,
            scaleID: 'y',
            value: props.baselinePowerKW,
          },
          alertAnnotation: {
            type: 'line',
            borderColor: 'rgba(100, 0, 0, 0.6)',
            borderWidth: 2,
            scaleID: 'y',
            value: props.alert?.params.standard_value,
          },
        },
      },
    },
  };
});

// Methods
const openModal = (index: number) => {
  selectedConsumption.value = props.offProductionConsumptions[index];
  modal.value = true;
};

// Computed
const consumption = computed(() => {
  return props.offProductionConsumptions.map((element: OffProductionConsumption) => element.avg_power_kW);
});

const labels = computed(() => {
  return props.offProductionConsumptions.map((element: OffProductionConsumption) =>
    element.start.toLocaleDateString(undefined, {
      weekday: 'short',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }),
  );
});

const barsBackgroundColors = computed(() => {
  return props.offProductionConsumptions.map((offProductionConsumption) => {
    if (offProductionConsumption.avg_power_kW > props.baselinePowerKW) return '#EC9875';
    else return '#F9DDD1';
  });
});

watch(
  () => appStore().getExploreDateRange,
  () => {
    resetZoom.value = true;
  },
);
</script>
<template>
  <div>
    <ui-chart
      type="bar"
      :data="{
        labels: labels,
        datasets: [
          {
            label: $t('analytics.avg_power_in_kW'),
            data: consumption,
            borderWidth: 1,
            borderColor: barsBackgroundColors,
            backgroundColor: barsBackgroundColors,
          },
        ],
      }"
      :options="options"
      :plugins="{
        'chartjs-plugin-zoom': true,
        legend: false,
        'chartjs-plugin-annotation': true,
      }"
      :reset-zoom="resetZoom"
      @bar-click="openModal($event)"
    />
  </div>

  <!-- Modal -->
  <ui-modal
    v-model="modal"
    width="900"
    :title="$t('global.off_consumption_of') + ' ' + dayjs(selectedConsumption?.start).locale('fr').format('dddd DD MMMM YYYY')"
  >
    <div class="grid gap-4">
      <div class="bg-gray-50 p-4 rounded-2 flex">
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.start') }}: </span>

            {{
              dayjs(selectedConsumption?.start)
                .locale('fr')
                .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
            }}
          </p>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.end') }}: </span>
            {{
              dayjs(selectedConsumption?.end)
                .locale('fr')
                .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
            }}
          </p>
        </div>
        <div class="mx-8 bg-gray-400 w-[1px]" />
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.average_power') }}: </span>
            {{ Math.round(selectedConsumption?.avg_power_kW ?? 0) }} kW
          </p>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.consumption') }}: </span>
            {{ Math.round(selectedConsumption?.consumption_kWh ?? 0) }} kWh
          </p>
        </div>
        <div class="mx-8 bg-gray-400 w-[1px]" />
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.cost') }}: </span>
            {{ formatNumberToIsoEuroNumber(selectedConsumption?.cost ?? 0) }}
          </p>
        </div>
      </div>
      <div class="bg-gray-50 p4 rounded-2 min-h-40 flex items-center justify-center">
        <off-consumption-zoom-graph v-if="selectedConsumption" :consumption="selectedConsumption" />
      </div>
    </div>

    <template #footer>
      <ui-button color="secondary" @click="modal = false">
        {{ $t('global.close') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
